var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showProgressBar)?_c('div',{staticClass:"progress-bar"}):_vm._e(),_vm._v(" "),_c('survey',{staticClass:"survey-container",attrs:{"survey":_vm.survey}}),_vm._v(" "),_c('form-navigation',_vm._b({key:_vm.randomKey},'form-navigation',{
      complete: _vm.complete,
      dataModel: _vm.dataModel,
      disabled: _vm.disabled,
      exit: _vm.exit,
      isFirstPage: _vm.isFirstPage,
      isLastPage: _vm.isLastPage,
      modalQuestionBody: _vm.modalQuestionBody,
      modalText: _vm.modalText,
      navigationText: _vm.navigationText,
      prevPage: _vm.prevPage,
      nextPage: _vm.nextPage,
    },false)),_vm._v(" "),(_vm.hasErrors)?_c('error-banner',_vm._b({key:_vm.errorKey},'error-banner',{ errorText: _vm.errorText },false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }