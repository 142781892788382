var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content-existing"},[_c('div',{staticClass:"dashboard-content-existing__add-button-wrapper"},[_c('a',{staticClass:"dashboard-content-existing__add-button",on:{"click":function($event){return _vm.goTo(_vm.addButton.path)}}},[_c('span',{staticClass:"dashboard-content-existing__add-button-text",domProps:{"textContent":_vm._s(_vm.addButton.text)}})])]),_vm._v(" "),_c('dashboard-content-existing-table',_vm._b({},'dashboard-content-existing-table',{
      commitments: _vm.commitments,
      iconPathDestroy: _vm.iconPathDestroy,
      iconPathEdit: _vm.iconPathEdit,
      iconPathShow: _vm.iconPathShow,
      iconPathShowInactive: _vm.iconPathShowInactive,
      iconPathWarning: _vm.iconPathWarning,
      text: _vm.text
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }